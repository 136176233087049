import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState} from '../../../app/store';
import { saveConsentRequest, 
  validateEkycConsentRequest, 
  callbackConsentRequest, 
  validateStandardConsentRequest, 
  saveStandardKycConsentRequest,
  standardKycCallbackRequest, 
  saveVideoKycConsentRequest,
  validateVideoKycConsentRequest,
  getAccessTokenForHyperverge,
  saveAddressDetailsRequest,
  getKycCompleteStatus,
  fetchAddressDetailsRequest,
  fetchStandardAddressDetailsRequest
} from '../apis/ConsentApi';
import { ConsentValidationResult } from '../apis/models/ConsentValidationResult';
import { StandardKycConsentRequest } from '../apis/models/StandardKycConsentRequest';
import { DigiLockerConsentRequest } from '../apis/models/DigiLockerConsentRequest';
import { SaveConsentResponse } from '../apis/models/SaveConsentResponse';
import { VideoKycConsentRequest } from '../../../apis/models/VideoKycConsentRequest';
import { SaveAddressDetails } from '../apis/models/SaveAddressDetails';
import { FetchAddressDetailsResponse } from '../apis/models/FetchAddressDetailsResponse';
import { FetchStandardAddressDetailsResponse } from '../apis/models/FetchStandardAddressDetails';

export interface ConsentState {
  consent: boolean;
  DigiLockerConsentValidationResult: ConsentValidationResult<DigiLockerConsentRequest>;
  StandardKycConsentValidationResult: ConsentValidationResult<StandardKycConsentRequest>;
  VideoKycConsentValidationResult: ConsentValidationResult<VideoKycConsentRequest>;
  SaveConsentResponse: SaveConsentResponse;
  callbackResult: any;
  standardKycCallbackResponse : any;
  loading: 'idle' | 'loading' | 'failed';
  error : any;
  hypervergeAccessToken: string | null;
  isAddressSaved: boolean;
  isKycCompleted: boolean;
  fetchedAddressDetails: FetchAddressDetailsResponse | null;
  openAddressDetailsModal: boolean | null;
  fetchedStandardAddressDetails: FetchStandardAddressDetailsResponse | null;
}

const initialState: ConsentState = {
  consent: false,
  DigiLockerConsentValidationResult: undefined as any,
  StandardKycConsentValidationResult: undefined as any,
  VideoKycConsentValidationResult: undefined as any,
  SaveConsentResponse: undefined as any,
  callbackResult: null,
  standardKycCallbackResponse: null as any,
  loading: 'idle',
  error : null,
  hypervergeAccessToken: null,
  isAddressSaved: false,
  isKycCompleted: false,
  fetchedAddressDetails: null,
  openAddressDetailsModal: null,
  fetchedStandardAddressDetails: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const validateEkycConsentRequestAsync = createAsyncThunk(
  'consent/validateEkycRequest',
  async (requestId: string) => {
    const response = await validateEkycConsentRequest(requestId);

    return response;
  }
);

export const validateStandardConsentRequestAsync = createAsyncThunk(
  'consent/validateStandardRequest',
  async (requestId: string) => {
    const response = await validateStandardConsentRequest(requestId);
    
    return response;
  }
);

export const validateVideoKycConsentRequestAsync = createAsyncThunk(
  'consent/validateVideoKycRequest',
  async (requestId: string) => {
    const response = await validateVideoKycConsentRequest(requestId);
    
    return response;
  }
);

export const saveConsentRequestAsync = createAsyncThunk(
  'consent/saveConsentRequest',
  async (requestId: string) => {
    const response = await saveConsentRequest(requestId);

    return response;
  }
);

export const saveStandardKycConsentRequestAsync = createAsyncThunk(
  'consent/saveStandardKycConsentRequest',
  async (requestId: string) => {
    const response = await saveStandardKycConsentRequest(requestId);

    return response;
  }
);

export const saveVideoKycConsentRequestAsync = createAsyncThunk(
    'consent/saveVideoKycConsentRequest',
    async (requestId: string) => {
      const response = await saveVideoKycConsentRequest(requestId);
      return response;
    }
  );


export const standardKycCallbackRequestAsync = createAsyncThunk(
  'consent/standardKycCallbackRequestAsync',
  async (requestId: string) => {
    const response = await standardKycCallbackRequest(requestId);
    return response;
  }
);


//callbackConsentRequest
export const callbackConsentRequestAsync = createAsyncThunk(
  'consent/callbackConsentRequest',
  async (req : {requestId: string, search: any}) => {
    const response = await callbackConsentRequest({requestId: req.requestId, search: req.search});

    return response;
  }
);


export const getHypervergeAccessToken = createAsyncThunk(
  'consent/getHypervergeAccessToken',
  async (requestId: string) => {
    const response = await getAccessTokenForHyperverge(requestId);

    return response;
  }
);

export const saveAddressDetails = createAsyncThunk(
  'save/address/details',
  async (data: SaveAddressDetails) => {
    const response = await saveAddressDetailsRequest(data);

    return response;
  }
);

export const fetchKycCompleteStatus = createAsyncThunk(
  'get/kyc/complete/status',
  async (data: {requestId: string, type: "insta" | "standard"}) => {
    const response = await getKycCompleteStatus(data);

    return response;
  }
);

export const fetchAddressDetails = createAsyncThunk(
  'fetch/address/details',
  async (data: {RequestId: string, Status: string}) => {
    const response = await fetchAddressDetailsRequest(data);

    return response;
  }
);

export const fetchStandardAddressDetails = createAsyncThunk(
  'fetch/standard/kyc/address/details',
  async (data: {RequestId: string, Status: string}) => {
    const response = await fetchStandardAddressDetailsRequest(data);

    return response;
  }
);

export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setConsent:(state, action: PayloadAction<boolean>) => {
      state.consent = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(validateEkycConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(validateEkycConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.DigiLockerConsentValidationResult = action.payload;
      })
      .addCase(validateEkycConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(validateStandardConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(validateStandardConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.StandardKycConsentValidationResult = action.payload;
      })
      .addCase(validateStandardConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(validateVideoKycConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(validateVideoKycConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.VideoKycConsentValidationResult = action.payload;
      })
      .addCase(validateVideoKycConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(saveConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.SaveConsentResponse = action.payload;
      })
      .addCase(saveConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(callbackConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(callbackConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.callbackResult = action.payload;
      })
      .addCase(callbackConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveStandardKycConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(saveStandardKycConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.SaveConsentResponse = action.payload;
      })
      .addCase(saveStandardKycConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(standardKycCallbackRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(standardKycCallbackRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.standardKycCallbackResponse = action.payload;
      })
      .addCase(standardKycCallbackRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveVideoKycConsentRequestAsync.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(saveVideoKycConsentRequestAsync.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.SaveConsentResponse = action.payload;
      })
      .addCase(saveVideoKycConsentRequestAsync.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(getHypervergeAccessToken.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(getHypervergeAccessToken.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.hypervergeAccessToken = action.payload;
      })
      .addCase(getHypervergeAccessToken.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveAddressDetails.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(saveAddressDetails.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.isAddressSaved = action.payload.SavedDetails;
        state.openAddressDetailsModal = false;
      })
      .addCase(saveAddressDetails.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchKycCompleteStatus.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchKycCompleteStatus.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.isKycCompleted = action.payload.Completed;
      })
      .addCase(fetchKycCompleteStatus.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAddressDetails.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchAddressDetails.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.fetchedAddressDetails = action.payload.fetchedDetails;
        state.openAddressDetailsModal = true;
      })
      .addCase(fetchAddressDetails.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchStandardAddressDetails.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchStandardAddressDetails.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.fetchedStandardAddressDetails = action.payload.fetchedStandardDetails;
        state.openAddressDetailsModal = true;
      })
      .addCase(fetchStandardAddressDetails.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })
  }
});

export const { setConsent } = consentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectConsent = (state: RootState) => state.consent;

export default consentSlice.reducer;