import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress
} from "@mui/material";
import { ConsentState, saveAddressDetails } from "../features/consent/redux/ConsentSlice";
import { SaveAddressDetails } from "../features/consent/apis/models/SaveAddressDetails";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { useLocation, useParams } from "react-router-dom";

interface AddressVerificationModalProps {
  open: boolean;
}

const AddressVerificationModal: React.FC<AddressVerificationModalProps> = ({ open }) => {
  const params = useParams();
  const searchQuery = new URLSearchParams(useLocation().search);
  const requestId = searchQuery.get("state") || String(params.trnxId) || "";
  const kycType = params.kycType;

  const [addressDetails, setAddressDetails] = useState<SaveAddressDetails>({
    Name: "",
    HouseNo: "",
    AddressLine: "",
    Pincode: "" as any,
    State: "",
    Country: "",
    RequestId: String(requestId) ,
    Type: kycType === "ekyc" ? "insta" : "standard"
  });

  const [errors, setErrors] = useState({
    Name: false,
    HouseNo: false,
    AddressLine: false,
    Pincode: false,
    State: false,
    Country: false
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { fetchedAddressDetails, loading, fetchedStandardAddressDetails } = useAppSelector((state: RootState): ConsentState => state.consent);

  useEffect(() => {
    if (fetchedAddressDetails || fetchedStandardAddressDetails) {
      setAddressDetails({
        ...addressDetails,
        Name: fetchedAddressDetails?.Name || fetchedStandardAddressDetails?.Name || "",
        HouseNo: fetchedAddressDetails?.House || fetchedStandardAddressDetails?.HouseNo || "",
        AddressLine: fetchedAddressDetails?.Address || fetchedStandardAddressDetails?.AddressLine1 || "",
        Pincode: Number(fetchedAddressDetails?.Pincode) || Number(fetchedStandardAddressDetails?.AddressPincode) || Number(""),
        State: fetchedStandardAddressDetails?.AddressState || "",
        Country: fetchedAddressDetails?.Country || "",
        RequestId: String(requestId)
      });
    }
  }, [fetchedAddressDetails, fetchedStandardAddressDetails]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressDetails({
      ...addressDetails,
      [name]: value,
    });
    setErrors({ ...errors, [name]: false }); // Clear error when the field is updated
  };

  const validateFields = () => {
    const newErrors = {
      Name: !addressDetails.Name.trim(),
      HouseNo: !addressDetails.HouseNo.trim(),
      AddressLine: !addressDetails.AddressLine.trim(),
      Pincode: !addressDetails.Pincode.toString().trim(),
      State: !addressDetails.State.trim(),
      Country: !addressDetails.Country.trim(),
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error); // Return true if no errors
  };

  const handleSubmit = () => {
    if (validateFields()) {
      dispatch(saveAddressDetails(addressDetails) as any);
    }
  };

  return (
    <Modal open={open} aria-labelledby="address-verification-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: isMobile ? 2 : 4
        }}
      >
        <Typography variant="h6" component="h2" textAlign="center">
          Please verify your Address Details below
        </Typography>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name on address proof"
              name="Name"
              value={addressDetails.Name}
              onChange={handleChange}
              placeholder="RAMESH KUMAR"
              required
              error={errors.Name}
              helperText={errors.Name ? "Name is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="House No./ Flat No."
              name="HouseNo"
              value={addressDetails.HouseNo}
              onChange={handleChange}
              placeholder="House No"
              required
              error={errors.HouseNo}
              helperText={errors.HouseNo ? "House No. is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="AddressLine"
              value={addressDetails.AddressLine}
              onChange={handleChange}
              placeholder="Address Line"
              multiline
              required
              error={errors.AddressLine}
              helperText={errors.AddressLine ? "Address Line is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Pincode"
              name="Pincode"
              value={addressDetails.Pincode}
              onChange={handleChange}
              placeholder="Pincode"
              required
              error={errors.Pincode}
              helperText={errors.Pincode ? "Pincode is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="State"
              name="State"
              value={addressDetails.State}
              onChange={handleChange}
              placeholder="State"
              required
              error={errors.State}
              helperText={errors.State ? "State is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Country"
              name="Country"
              value={addressDetails.Country}
              onChange={handleChange}
              placeholder="Country"
              required
              error={errors.Country}
              helperText={errors.Country ? "Country is required" : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddressVerificationModal;
